import styled from '@emotion/styled';

export const SimpleButton = styled.div`
    background-color: white;
    padding: 8px 16px;
    border-radius: 20px;
    font-weight: 600;
    border: 1px solid rgb(209, 208, 206);
    flex-direction: row;
    align-items: stretch;
    display: flex;
    color: black;
    text-decoration: none;

    &:hover {
        background-color: #000;
        cursor: pointer;
        border: 1px solid #000;
        color: #FFF;
    }
`;

export const MobileWrapper = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;

  .content {
    padding: 20px;
    padding-top: 80px;
    overflow-y: auto;
  }

  .close {
    position: absolute;
    right: 24px;
    cursor: pointer;
    top: 18px;

    svg {
      font-size: 24px;
    }
  }
  .menu-item {
    border-bottom: 1px solid #E0E0E0;
    padding: 20px 0;
    position: relative;

    &.profile {
      margin-bottom: 40px;
    }
    &:hover {
      cursor: pointer;
      border-bottom: 1px solid #000;
    }
    .more {
      position: absolute;
      top: 25px;
      right: 10px;
    }
  }
  .parent {
    text-indent: 20px;
    margin-bottom: 30px;
    position: relative;
    cursor: pointer;
    svg {
      position: absolute;
      left: 0;
      top: 2px;
    }
  }
  .menu-link {
    border-bottom: 1px solid #E0E0E0;
    padding: 20px 0;
    &:hover {
      cursor: pointer;
      border-bottom: 1px solid #000;
    }
  }
`;

export const MenuClick = styled.div`
    cursor: pointer;
`;
export const SearchInput = styled.input`
  display: block;
  appearance: none;
  border: 0;

  flex: 1 1 auto;
  color: #fff;
  padding: 8px 12px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 0;
  min-width: 0;
  max-width: 200px;

  ::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
`;
