import {
  forwardRef,
  useEffect,
  useLayoutEffect,
  ReactNode,
  RefObject,
  FC,
  RefAttributes,
  useState,
} from 'react';
import {createPortal} from 'react-dom';
import {TabOutlineController} from './TabOutlineController';

export interface OverlayProps {
  className?: string;
  children: ReactNode | null;
  parentRef?: RefObject<HTMLElement>;
}

export const Overlay = forwardRef<Element, OverlayProps>(({
  className, children, parentRef,
}: OverlayProps, ref) => {
  const [overlay] = useState(document.createElement('div'));

  const parentElement = parentRef?.current || document.body;

  useEffect(() => {
    const {style} = overlay;

    style.position = 'fixed';
    style.overflow = 'visible';
    style.margin = '0';
    style.padding = '0';
    style.zIndex = '100';

    parentElement.append(overlay);

    return () => {
      overlay.remove();
    };
  }, [overlay, parentElement]);

  useLayoutEffect(() => {
    overlay.className = className ?? '';
  }, [className, overlay]);

  useLayoutEffect(() => {
    if (typeof ref === 'function') {
      ref(overlay);
    } else if (ref) {
      Object.assign(ref, {current: overlay});
    }
  }, [overlay, ref]);

  return (
    children
      ? createPortal(
        <TabOutlineController>{children}</TabOutlineController>,
        overlay,
      )
      : null
  );
}) as FC<OverlayProps & RefAttributes<Element>>;
