/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  forwardRef, ReactNode, SVGAttributes, useRef,
} from 'react';
import {Property} from 'csstype';
import {
  motion, Transition,
} from 'framer-motion';
import styled from '@emotion/styled';
import {useTheme} from '../utils/hooks';

export interface ProgressCircleProps {
  size: number;
  primaryColor?: Property.Color;
  secondaryColor?: Property.Color;
  rotation?: number;
  strokeWidth?: number;
  strokeLinecap?: SVGAttributes<SVGCircleElement>['strokeLinecap'];
  value?: number;
  ease?: Transition;
  duration?: number;
  formatLabel?: (value: number) => string;
  children?: ReactNode;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const SVG = styled.svg`
  position: absolute;
  inset: 0;
`;

export const ProgressCircle = forwardRef<HTMLDivElement, ProgressCircleProps>((
  {
    size,
    primaryColor,
    secondaryColor,
    rotation = 0,
    strokeWidth = 1,
    strokeLinecap,
    value = 1,
    ease = 'easeOut',
    duration = 0,
    formatLabel,
    children,
  },
  ref,
) => {
  const theme = useTheme();

  const cXY = size / 2;
  const radius = (size - strokeWidth) / 2;
  const circumference = Math.PI * 2 * radius;
  const pathLength = circumference * value;
  const pathOffset = rotation * -circumference + 0.25 * circumference;

  const circleRef = useRef<SVGCircleElement>(null);

  return (
    <Wrapper
      css={{
        position: 'relative',
        width: size,
        height: size,
      }}
      ref={ref}
    >
      <SVG
        width="100%"
        height="100%"
        viewBox={`0 0 ${size} ${size}`}
      >
        <circle
          cx={cXY}
          cy={cXY}
          r={radius}
          stroke={
            secondaryColor !== undefined
              ? secondaryColor
              : theme.chartSecondaryColor
          }
          strokeWidth={strokeWidth}
          fill="none"
        />
        <motion.circle
          ref={circleRef}
          cx={cXY}
          cy={cXY}
          r={radius}
          stroke={value ? primaryColor || theme.chartPrimaryColor : 'transparent'}
          strokeWidth={strokeWidth}
          strokeDasharray={`${pathLength} ${circumference - pathLength}`}
          strokeDashoffset={pathOffset}
          strokeLinecap={strokeLinecap}
          fill="none"
          initial={{'--value': 0} as any}
          animate={{'--value': pathLength} as any}
          transition={{
            ease,
            duration,
          }}
          onUpdate={({'--value': value}: {'--value': number}) => {
            if (!circleRef.current) {
              return;
            }
            circleRef.current.setAttribute('stroke-dasharray', `${value} ${circumference - value}`);
            if (formatLabel) {
              circleRef.current.textContent = formatLabel(value);
            }
          }}
        />
      </SVG>
      {children}
    </Wrapper>
  );
});
