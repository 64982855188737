import {ReactElement} from 'react';
import {Property} from 'csstype';
import {
  Block,
  Item,
  ItemDefinitions,
  Label,
  Separator,
} from '@snapper/core';
import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import * as CSS from '../styles/input-styles';
import {
  Attach, MenuContext,
} from '../types/menu';
import {DropdownMenu} from '../items/DropdownMenu';

export interface ItemTypes {
  menu: {
    label: string;
    items: MenuItem[];
    attach?: Attach[];
  };
  button: {
    label: string;
    onClick: (context: MenuContext) => void;
    attach?: Attach[];
  };
  simpleButton: {
    label: string;
    icon?: IconProp;
    attach?: Attach[];
    onClick: (context: MenuContext) => void;
  };
  separator: {
    attach?: Attach[];
    margin?: string[];
  };
  textSeparator: {
    label?: string;
    attach?: Attach[];
  };
  title: {
    label: string;
    onClick?: () => void;
    href?: string;
    attach?: Attach[];
  };
  label: {
    label: string;
    bold?: boolean;
    attach?: Attach[];
    onClick?: () => void;
    href?: string;
    size?: Property.FontSize;
  };
  featureLink: {
    label: string;
    subLabel?: string;
    onClick?: () => void;
    href?: string;
    attach?: Attach[];
  };
  custom: {
    render: (context: MenuContext) => ReactElement;
  };
}

export type MenuItem<T = keyof ItemTypes> = Item<ItemTypes, T> & {index?: number; attach?: Attach[]};

export const TopMenuItem = styled(Block)<{active?: boolean}>(
  css`
    cursor: pointer;
    transition: background 0.2s ease-out;

    :hover {
      background: rgba(0, 0, 0, 0.2);
    }
  `,
  ({active}) => active && css`
    background: rgba(0, 0, 0, 0.2);
  `,
);

export const menuDefinitions: ItemDefinitions<ItemTypes, MenuContext> = {
  menu: {component: DropdownMenu},
  button: {
    render: ({
      label,
      onClick,
    }, context) => context.isMobile
      ? <div>btn</div>
      : (
        <TopMenuItem
          color="#fff"
          nowrap
          onClick={() => onClick(context)}
        >
          {label}
        </TopMenuItem>
      ),
  },
  separator: {
    render: ({margin}) => (
      <Separator
        margin={margin}
      />
    ),
  },
  textSeparator: {
    render: ({label}) =>
      (
        <Label
          bold
          style={{
            paddingTop: 50,
            paddingBottom: 10,
          }}
        >
          {label}
        </Label>
      ),
  },
  title: {
    render: (
      {
        label,
        href,
        onClick,
      },
      {
        history,
        closeMenu,
      },
    ) => {
      const clickable = onClick || history && href;

      return (
        <Label
          bold
          size="1.5em"
          onClick={clickable
            ? () => {
              closeMenu();
              if (onClick) {
                onClick();
              }else if (history && href) {
                history.push(href);
              }
            }
            : undefined}
          css={[
            css`
              color: #000;
            `,
            clickable && css`
              cursor: pointer;
              :hover, :focus {
                color: #000;

                span:first-child {
                  text-decoration: underline;
                }
              }
            `,
          ]}
        >
          {label}
        </Label>
      );
    },
  },
  simpleButton: {
    render: ({
      label,
      icon,
      onClick,
    }, context) =>
      context.isMobile ? (
        <div
          onClick={() => {
            onClick(context);
          }}
          className="menu-item"
        >
          {icon && (
            <FontAwesomeIcon
              style={{marginRight: 10}}
              icon={icon}
            />
          )}
          {label}
        </div>
      ) : (
        <CSS.SimpleButton
          onClick={() => {
            onClick(context);
          }}
        >
          {icon && (
            <FontAwesomeIcon
              style={{marginRight: 5}}
              icon={icon}
            />
          )}
          {label}
        </CSS.SimpleButton>
      ),
  },
  label: {
    render: ({
      label,
      bold,
      size,
      onClick,
      href,
    }, {
      history,
      closeMenu,
    }) => {
      const clickable = onClick || history && href;

      return (
        <Label
          bold={bold}
          size={size}
          onClick={clickable
            ? () => {
              closeMenu();
              if (onClick) {
                onClick();
              }else if (history && href) {
                history.push(href);
              }
            }
            : undefined}
          css={[
            css`
              color: #000;
            `,
            clickable && css`
              cursor: pointer;
              :hover, :focus {
                color: #000;

                span:first-child {
                  text-decoration: underline;
                }
              }
            `,
          ]}
        >
          {label}
        </Label>
      );
    },
  },
  featureLink: {
    render: ({
      label,
      subLabel,
      onClick,
      href,
    }, {
      history,
      isMobile,
      closeMenu,
    }) => {
      const clickable = onClick || history && href;

      return isMobile ? (
        <div
          className="menu-link"
          onClick={clickable
            ? () => {
              closeMenu();
              if (onClick) {
                onClick();
              } else if (history && href) {
                history.push(href);
              }
            }
            : undefined}
        >
          {label}
        </div>
      ) : (
        <Label
          onClick={clickable
            ? () => {
              closeMenu();
              if (onClick) {
                onClick();
              }else if (history && href) {
                history.push(href);
              }
            }
            : undefined}
          css={[
            css`
              color: #000;
            `,
            clickable && css`
              cursor: pointer;
              :hover, :focus {
                color: #000;

                span:first-child {
                  text-decoration: underline;
                }
              }
            `,
          ]}
        >
          <Label
            bold
            size="1.5em"
          >
            {label}
          </Label>
          <br />
          <Label
            marginTop="0.5em"
            size="1em"
          >
            {subLabel}
          </Label>
        </Label>
      );
    },
  },
  custom: {render: ({render}, context) => render(context)},
};
