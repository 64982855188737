import {darken} from 'polished';
import {Theme} from '../types/theme';

export const lightTheme: Theme = {
  name: 'Light',
  primaryColor: '#0cb3ff',
  success: '#028142',
  warn: '#f05d00',
  color: '#333',
  lightColor: '#fff',
  fadedColor: '#616161',
  boldFontWeight: 600,
  cellBorderColor: '#eaebed',
  listSeparatorColor: '#eee',
  boxBackgroundColor: '#eee',
  infoBackgroundColor: '#eee',
  backgroundColor: '#fff',
  backgroundHoverColor: '#ececec',
  oddBackgroundColor: '#FBFBFB',
  headerColor: '#000',
  tableHeaderColor: '#424242',
  inputColor: '#111',
  inputPadding: '8px 8px',
  inputSmallPadding: '6px 6px',
  inputFontSize: '0.9em',
  inputSmallFontSize: '0.9em',
  inputLabelColor: '#949393',
  inputLabelFontWeight: 'normal',
  inputLabelFontSize: '0.9em',
  inputLabelLineHeight: '1.5',
  inputLabelMargin: '0',
  inputBackgroundColor: '#fefefe',
  inputBackgroundColorFocus: '#fefefe',
  inputBorderColor: '#D9D9D9',
  inputBorderRadius: '5px',
  inputSmallBorderRadius: '5px',
  inputDropdownBackgroundColor: '#fefefe',
  inputLineHeight: '1.5',
  inputSmallLineHeight: '1',
  inputIconColor: '#A8A8A8',
  placeholderColor: '#8C8C8C',
  placeholderColorFocus: '#6a6868',
  buttonBorderRadius: '5px',
  buttonPadding: '10px 14px',
  buttonSmallBorderRadius: '5px',
  buttonSmallPadding: '4px 8px',
  buttonTransition:
    'background-color 0.1s ease-out, border-color 0.1s ease-out',
  boxShadow:
    '0 1px 2px 0 rgba(0, 0, 0, 0.12)',
  buttonBoxShadow:
    'none',
  linkColor: '#008CBA',
  linkColorHover: '#008CBA',
  linkTextDecoration: 'none',
  linkTextDecorationHover: 'underline',
  avatarUseRandomColor: false,
  avatarBackgroundColor: '#ededed',
  avatarRandomColorOptions: {luminosity: 'light'},
  chartPrimaryColor: '#0CB3FF',
  chartSecondaryColor: '#C4C4C4',
  chartSecondaryColorLight: '#EDEDED',
  chartLineColor: '#6A6868',
  chartLabelColor: '#000',
  fadedBackgroundColor: '#eee',
  hoverDuration: '0.2s',
  buttons: {
    'default': {
      color: '#797979',
      colorHover: '#444',
      backgroundColor: '#f2f2f2',
      backgroundColorHover: darken(0.1, '#f2f2f2'),
      borderColor: 'transparent',
      borderColorHover: 'transparent',
    },
    primary: {
      color: '#fff',
      colorHover: '#fff',
      backgroundColor: '#0cb3ff',
      backgroundColorHover: darken(0.1, '#0cb3ff'),
      borderColor: 'transparent',
      borderColorHover: 'transparent',
    },
    error: {
      color: '#fff',
      colorHover: '#fff',
      backgroundColor: '#f05d00',
      backgroundColorHover: darken(0.1, '#f05d00'),
      borderColor: 'transparent',
      borderColorHover: 'transparent',
    },
    transparent: {
      color: '#797979',
      colorHover: '#444',
      backgroundColor: 'transparent',
      backgroundColorHover: '#f2f2f2',
      borderColor: 'transparent',
      borderColorHover: 'transparent',
    },
  },
};
