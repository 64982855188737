import axios, {CancelTokenSource} from 'axios';
import {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import {
  MenuContext,
  SearchConfig, SearchResult,
} from '../types/menu';

const cancelMsg = 'Operation canceled by the user.';

interface UseSearchParams {
  search: (searchTerm: string) => void;
  searchResult: SearchResult | null;
};

export const useSearch = (searchConfig: Omit<SearchConfig, 'placeholder'> | undefined, context: MenuContext): UseSearchParams => {
  const {
    search: onSearch,
    method = 'GET',
    url,
    queryParam = 'q',
    withCredentials = false,
  } = searchConfig || {};

  const unmounted = useRef<boolean>(false);
  const source = useRef<CancelTokenSource>();
  const [searchResult, setSearchResult] = useState<SearchResult | null>(null);

  const search = useCallback((searchTerm: string) => {
    if (onSearch) {
      setSearchResult(onSearch(searchTerm, context));
    } else if (url) {
      if (source.current) {
        source.current.cancel(cancelMsg);
      }
      const tokenSource = axios.CancelToken.source();

      source.current = tokenSource;
      axios.request({
        url,
        method,
        params: {[queryParam]: searchTerm},
        withCredentials,
        cancelToken: tokenSource.token,
      })
        .then(({data}) => {
          if (!unmounted.current) {
            setSearchResult(data);
          }

          return data;
        })
        .catch(error => {
          console.error(error);

          if (!unmounted.current) {
            setSearchResult(null);
          }
        });
    }
  }, [onSearch, url, context, method, queryParam, withCredentials]);

  useEffect(
    () => () => {
      unmounted.current = true;
      if (source.current) {
        source.current.cancel(cancelMsg);
      }
    },
    [source],
  );

  return {
    search,
    searchResult,
  };
};
