/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Property} from 'csstype';
import {pick} from 'dot-object';
import {getLuminance} from 'polished';
import {
  Dispatch, SetStateAction,
} from 'react';

export type TextMetricsWithHeight = TextMetrics & {readonly height: number};

/**
 * Returns a function to measure the dimensions of a string rendered with a specific font and font size.
 */
export const measureText = (
  text: string,
  fontSize: Property.FontSize,
  fontFamily: Property.FontFamily,
): TextMetricsWithHeight | undefined => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    console.error('Coult not create 2d-context');

    return undefined;
  }

  ctx.font = `${fontSize} ${fontFamily}`;
  const metrics = Object.assign(
    ctx.measureText(text),
    {height: Number.parseFloat(fontSize.toString())},
  );

  canvas.remove();

  return metrics;
};

type Map = Record<string, any>;

export type PropertyGetter<T, V> = keyof T | ((object: T) => V);

export const getProperty = <T extends (K extends keyof T ? {[key: string]: V} : Map), K extends PropertyGetter<T, V>, V>(key: K, object: T): V =>
  typeof key === 'function' ? key(object) : key && object[key as keyof T];

export const dotSelect = (key: string) => (object: any): any => pick(key, object);

export const isDark = (color: string, maxLuminance = 0.333): boolean => {
  let isDark;

  try {
    isDark = getLuminance(color) <= maxLuminance;
  } catch {
    isDark = false;
  }

  return isDark;
};

export const round = (value: string | number, factor = 100): number => {
  const number = Number(value);

  if (!Number.isNaN(number)) {
    return Math.round((number + Number.EPSILON) * factor) / factor;
  }

  return number;
};

export const tryParseFloat = <T>(value: T): T | number => {
  if (value == null) {
    return value;
  }

  if (typeof value !== 'string' && !(value as {toString?(): string})?.toString) {
    return value;
  }

  const parsed = Number.parseFloat((value as {toString(): string}).toString());

  return !Number.isNaN(parsed) ? parsed : value;
};

export const toggle = (value: boolean): boolean => !value;

export const toggleListItem = <T>(setState: Dispatch<SetStateAction<T[]>>, value: T, key?: keyof T): void => {
  setState(values => {
    if (values.some(item => (key ? item[key] : item) === (key ? value[key] : value))) {
      return values.filter(item => (key ? item[key] : item) !== (key ? value[key] : value));
    }

    return [...values, value];
  });
};

export const addListItem = <T>(setState: Dispatch<SetStateAction<T[]>>, value: T): void => {
  setState(values => [...values, value]);
};

export const removeListItem = <T>(setState: Dispatch<SetStateAction<T[]>>, value: T, key?: keyof T): void => {
  setState(values => values.filter(item => (key ? item[key] : item) !== (key ? value[key] : value)));
};